import { useMutation } from '@tanstack/react-query';
import { Divider } from 'components';
import { Card, CardContent } from 'components/card';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

import { Fade } from '../../../components/transition';
import { notifyIncompleteProfile } from '../../../services/cse';

const NotifyMissingCustomerCseProfile = () => {
  const { t } = useTranslation('customer');
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: notifyIncompleteProfile,
    onSuccess: () =>
      toast.success(t('account.request.missingCseProfile.notify.success')),
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message ??
        t('account.request.missingCseProfile.notify.error');
      toast.error(errorMessage);
    }
  });
  return (
    <Card compact>
      <CardContent>
        <Paragraph>{t('account.request.missingCseProfile.title')}</Paragraph>
        <Divider />
        <Paragraph className="text-sm text-gray-800" gutterBottom>
          {t('account.request.missingCseProfile.notify.description')}
        </Paragraph>
        <Fade className="self-end">
          {!isSuccess && (
            <Button
              variant="outline"
              size="small"
              label={t('account.request.missingCseProfile.notify.button')}
              isLoading={isPending}
              onClick={mutate}
            />
          )}
        </Fade>
      </CardContent>
    </Card>
  );
};

export default NotifyMissingCustomerCseProfile;
