import { Divider } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

const FillCustomerCseProfile = () => {
  const { t } = useTranslation('customer');

  return (
    <Card compact>
      <CardContent>
        <Paragraph>{t('account.request.missingCseProfile.title')}</Paragraph>
        <Divider />
        <Paragraph className="text-sm text-gray-800" gutterBottom>
          {t('account.request.missingCseProfile.fillIn.description')}
        </Paragraph>
        <Link className="self-end" to="/admin/cse-identity-management">
          <Button
            variant="outline"
            size="small"
            label={t('account.request.missingCseProfile.fillIn.button')}
          />
        </Link>
      </CardContent>
    </Card>
  );
};

export default FillCustomerCseProfile;
