import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isPast } from 'date-fns';
import jwtDecode from 'jwt-decode';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { getAccessTokenByRefreshToken } from 'services/authentication/refreshToken';
import { getAuthenticatedLawyerInfo } from 'services/lawyers/info';
import {
  AuthenticatedMemberInfo,
  getAuthenticatedMemberInfo
} from 'services/member';
import { useLocalStorage } from 'usehooks-ts';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { AuthLocalStorage } from './auth/auth.utils';

type UserType = 'member' | 'lawyer';

type Jwt = {
  sub: string;
  user_type: UserType;
  exp: number;
};

export interface IAuthContext {
  userType: UserType | null;
  accessToken: AuthLocalStorage['accessToken'] | null;
  refreshToken: AuthLocalStorage['refreshToken'] | null;
  setAccessToken: React.Dispatch<
    React.SetStateAction<AuthLocalStorage['accessToken']>
  >;
  setRefreshToken: React.Dispatch<
    React.SetStateAction<AuthLocalStorage['refreshToken']>
  >;
  logout: () => void;
  member?: AuthenticatedMemberInfo;
  isTokenValid: boolean;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

const storageOptions = {
  deserializer: (value: string) => value,
  serializer: (value: string) => value
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const [accessToken, setAccessToken, removeAccessToken] =
    useLocalStorage<string>('accessToken', '', storageOptions);
  const [refreshToken, setRefreshToken, removeRefreshToken] =
    useLocalStorage<string>('refreshToken', '', storageOptions);

  const tokenData = useMemo(() => {
    if (!accessToken) return { userType: null, exp: 0 };
    try {
      const decoded = jwtDecode<Jwt>(accessToken);
      return {
        userType: decoded.user_type,
        exp: decoded.exp
      };
    } catch (error) {
      return { userType: null, exp: 0 };
    }
  }, [accessToken]);

  const { userType, exp } = tokenData;

  const isTokenValid = useMemo(() => {
    if (!accessToken || !exp) return false;
    return !isPast(new Date(exp * 1000));
  }, [accessToken, exp]);

  const isAuthenticated = !!accessToken && isTokenValid;

  const { mutateAsync: refreshAuthAsync } = useMutation({
    mutationKey: ['refresh-access-token'],
    mutationFn: getAccessTokenByRefreshToken,
    onSuccess: (data) => {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
    },
    onError: () => {
      logout();
    }
  });

  const refreshAuth = async () => {
    try {
      await refreshAuthAsync();
    } catch (error) {
      logout();
    }
  };

  useEffect(() => {
    let refreshTimeout: NodeJS.Timeout | null = null;

    const scheduleTokenRefresh = () => {
      if (!accessToken || !exp) return;

      const expiresIn = exp * 1000 - Date.now();
      const refreshTime = expiresIn - 60000;

      if (refreshTime > 0) {
        refreshTimeout = setTimeout(refreshAuth, refreshTime);
      } else if (expiresIn > 0) {
        refreshAuth();
      }
    };

    if (isAuthenticated) {
      scheduleTokenRefresh();
    }

    return () => {
      if (refreshTimeout) clearTimeout(refreshTimeout);
    };
  }, [accessToken, exp, isAuthenticated]);

  const login = (newAccessToken: string, newRefreshToken: string) => {
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
  };

  const logout = () => {
    queryClient.clear();
    removeAccessToken();
    removeRefreshToken();
    Sentry.getCurrentScope().setUser(null);
  };

  const { data: member, isPending: isMemberLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: getAuthenticatedMemberInfo,
    enabled: userType === 'member' && isTokenValid
  });

  const { data: lawyer, isPending: isLawyerLoading } = useQuery({
    queryKey: ['authenticated-lawyer-info'],
    queryFn: getAuthenticatedLawyerInfo,
    enabled: userType === 'lawyer' && isTokenValid
  });

  useEffect(() => {
    if (lawyer) {
      Sentry.setUser({ email: lawyer.email, id: lawyer.id });
    } else if (member) {
      Sentry.setUser({ email: member.email, id: member.id });
    } else {
      Sentry.getCurrentScope().setUser(null);
    }
  }, [lawyer, member]);

  const contextValue = useMemo(
    () => ({
      userType,
      accessToken,
      refreshToken,
      setAccessToken,
      setRefreshToken,
      logout,
      login,
      refreshAuth,
      member,
      isAuthenticated,
      isTokenValid
    }),
    [accessToken, refreshToken, userType, member, isTokenValid, isAuthenticated]
  );

  if (
    (userType === 'member' && isMemberLoading) ||
    (userType === 'lawyer' && isLawyerLoading)
  ) {
    return <LayoutLoadingSkeleton />;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default AuthProvider;
