import { ShareIcon } from '@heroicons/react/24/outline';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Title } from 'components';
import { Card } from 'components/card';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberQuestion, getMemberQuestion } from 'services/question';

import RateAnswer from 'features/rating/RateAnswer';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { isPositiveRating } from '../../services/rating';
import ShareMemberQuestion from './ShareMemberQuestion';

interface MemberQuestionShortcutProps {
  questionId: MemberQuestion['id'];
  setShowGoogleRating: (showGoogleRating: boolean) => void;
}

const MemberQuestionShortcut = ({
  questionId,
  setShowGoogleRating
}: MemberQuestionShortcutProps) => {
  const { t } = useTranslation('customer');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const {
    data: question,
    isPending,
    isSuccess,
    refetch: refetchMemberQuestion
  } = useQuery({
    queryKey: ['member-question', questionId],
    queryFn: () => getMemberQuestion(questionId)
  });

  if (isPending || !isSuccess) return <div />;

  return (
    <Card compact>
      <Title gutterBottom variant="h4" component="h3">
        {t('account.request.actions.title')}
      </Title>
      <div className="justify-stretch flex flex-col gap-4">
        {question.can_rate_answer && (
          <>
            <Button
              variant="outline"
              size="small"
              fullWidth
              onClick={() => setIsRateModalOpen(true)}
              label={t('account.question.voteCta')}
            />
            <LayoutModal
              isModalOpen={isRateModalOpen}
              handleModalClose={() => {
                setIsRateModalOpen(false);
              }}
            >
              <RateAnswer
                onRateSubmitted={(note) => {
                  if (isPositiveRating(note)) {
                    setShowGoogleRating(true);
                  }
                  setIsRateModalOpen(false);
                  refetchMemberQuestion();
                }}
                answerId={question.answer_id}
              />
            </LayoutModal>
          </>
        )}
        {question.can_share_question && (
          <>
            <Button
              icon={<ShareIcon className="w-5" />}
              variant="outline"
              size="small"
              onClick={() => setIsShareModalOpen(true)}
              fullWidth
              label={t('account.question.share.cta')}
            />
            <LayoutModal
              isModalOpen={isShareModalOpen}
              handleModalClose={() => setIsShareModalOpen(false)}
            >
              <ErrorBoundary fallback={<GenericErrorCard />}>
                <ShareMemberQuestion
                  questionId={questionId}
                  onShare={() => setIsShareModalOpen(false)}
                />
              </ErrorBoundary>
            </LayoutModal>
          </>
        )}
      </div>
    </Card>
  );
};

export default MemberQuestionShortcut;
