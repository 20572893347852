import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { handleClearAuthAndLoginRedirect } from './auth/auth.utils';

interface ReactQueryProviderProps {
  children: React.ReactNode;
}

export default function ReactQueryProvider({
  children
}: ReactQueryProviderProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        throwOnError: true,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry(failureCount: number, error: any) {
          if (
            error.status === 401 &&
            error?.response?.data?.code === 'invalid_jwt'
          ) {
            handleClearAuthAndLoginRedirect();
            return false;
          }
          if (error.status === 404) return false;
          if (failureCount < 2) return true;
          return false;
        }
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
