import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders
} from 'axios';
import QueryString from 'qs';

const PYTHON_API_AXIOS_CONFIG: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_NEW_API_EKIE_URL,
  paramsSerializer: (params: any) =>
    QueryString.stringify(params, { arrayFormat: 'repeat' })
};

export const apiPublicPythonClient: AxiosInstance = axios.create(
  PYTHON_API_AXIOS_CONFIG
);
export const apiPrivatePythonClient: AxiosInstance = axios.create(
  PYTHON_API_AXIOS_CONFIG
);

apiPrivatePythonClient.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
    } as AxiosRequestHeaders
  }),
  (error) => Promise.reject(error)
);
